.PuCPrint
{
    /*background:linear-gradient(135deg, #5D6874 0%,#222222 100%);*/
    /* background: #7EC8ECAA; */
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 20rem;
    height: 20rem;
    flex-direction:column;
    padding: .5em;
    border-radius:.5rem;
}

.pumpButtonPrint{
    border-style: none;
    margin: 0.25rem;
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

.PumpStatusButtonPrint{
    color: #364954;
    border-style: none;
    margin: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

.PumpTotalizerButton{
    border-style: none;
    margin: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

.pumpCardSection{
    display:flex;
    flex-direction:column;
}

.PuCInfoPrint{
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: #364954;
    border-radius: 15px;
    margin: 0.2rem;
}

#NozzleButtonPrint{
    background-color: #7EC8ECAA !important;
}

#NozzleBadgePrint{
    color: #364954;
}

#StatusBadge{
    color: #364954;
}

#TotalizerButtonPrint{
    background-color: #7EC8ECAA;
    display: flex;
    justify-content: space-around;
}

.PuCPrint{
    display: none;
}

@media print {
.PuC{
    display: none;
}

.PuCPrint{
    display: block;
    page-break-after: always
}
}