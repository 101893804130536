.modal-title{
    height: 10px !important;
}

.TotalizerBadge {
    background-color: white;
    color: black !important;
    width: 35%;
}

#TotalizerModalBody{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#TotalizerModalInput{
    display: flex;
    justify-content: space-around;
}

#TotalizerModalButtons{
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 70%;
    align-self: center;
}

.TotalizerButton{
    background-color: #364954 !important;
    border-style: none !important;
    margin: .2em;
    border-style: none;
}