.Buttons Button {
    padding: 6px 8px 6px 8px;
    text-decoration: none;
    font-size: 25px;
    color: #364954;
    display: block;
    background-color: transparent;
    border-color: transparent;
  }
  
  /* When you mouse over the navigation links, change their color */
  .Buttons Button:hover {
    color: #7EC8EC;
  }

  .Buttons {
    display: flex;
    justify-content: flex-start;
  }

  .nozzleModal{
    background: url(Background.jpg);
  }

  #contained-modal-title-vcenter
  {
    margin-bottom: 0.5em;
  }