.footer{
  background-color: #7EC8ECAA;
  bottom: 0;
  width: 100%;
  margin-top: auto;
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  font-weight: bold;
}

@media only screen and (max-width: 950px) {
  .footer {
      font-size: 0.7rem;
  }

  #hideMenuButton{
    font-size: 1.3em !important;
  }

  #logoutButton{
    font-size: 1.3em !important;
  }
}

@media only screen and (max-width: 675px) {
  .footer {
      font-size: 0.35rem;
  }

  #hideMenuButton{
    font-size: 1em !important;
  }

  #logoutButton{
    font-size: 1em !important;
  }
}

#ticker{
  bottom: 0;
  width: 100%;
  position:fixed;
  height: 5vh;
  font-weight: bold;
}

@media print {
  .footer{
    display: none;
  }
}
/*position: fixed;
  bottom: 0;
  text-align: center;
  clear: both;
  font-size: 0.5em;
  padding-top: 50px;
  background-color: #f5f5f5;
  float:left;
  width:100%;*/

  #logoutButton {
    padding: 6px 8px 6px 8px;
    text-decoration: none;
    font-size: 25px;
    color: #364954;
    display: block;
    background-color: transparent;
    border-color: transparent;
    margin: auto 0.5em;
}

#hideMenuButton {
  padding: 6px 8px 6px 8px;
  text-decoration: none;
  font-size: 25px;
  color: #364954;
  display: block;
  background-color: transparent;
  border-color: transparent;
  margin: auto 0.5em;
}

#logoutDiv{
  display: flex;
  margin-left: auto;
}

#logoutText{
  margin: auto 1em;
}

#clockDiv{
  margin: auto 1em;
}

#versionText{
  margin: auto 1em;
}
