.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10em;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    padding: 2em;
}

@media only screen and (max-width: 675px) {
    .login-wrapper {
        margin: 1em;
    }
  }

.form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

label p{text-align: center;}

.login-button{
    margin-top: 1em;
    border-radius: 10%;
}

#outlined-error-helper-text{
    margin: 1em;
}

#outlined-password-helper-text{
    margin: 1em;
}

.textField{
    margin: 1em;
}