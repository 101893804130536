.PeriodicContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px; /* Adjust this value to change spacing between components */
    width: 100%;
    height: 100%;
    padding: 0 20px;  /* Added padding to move away from the edges */
}

.PeriodicContainer2 {
    width: 100%;
    height: 100%;
    padding: 20px 20px;  /* Added padding to move away from the edges */
}

.PeriodicComponent {
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Push the button to the bottom */
    padding: 10px;
    background-color: #ffffff; /* Assuming you want a white background for the floating effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Horizontal offset, vertical offset, blur radius, and shadow color */
    border-radius: 5px; /* Rounded edges for a more "floating" feel */
    transition: all 0.3s ease; /* Optional: Transition effect for hover interaction */
}

/* Optional: Slight scale-up effect on hover to emphasize the floating look */
.PeriodicComponent:hover {
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.PeriodicComponent2:hover {
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

/* This targets the button within the .PeriodicComponent */
.PeriodicComponent > Button {
    align-self: flex-end; /* Move the button to the right */
    margin-top: 10px; /* Optional: Add some spacing on top of the button */
}

/* Reduce the width of the tables inside the .PeriodicComponent to make them smaller */
.PeriodicComponent > TableContainer {
    width: 90%; /* Reduce width to 90% (or any value of your choice) */
    margin: auto; /* Centers the table */
}

input[type="time"]::-webkit-datetime-edit-fields-wrapper,
input[type="time"]::-webkit-datetime-edit-text {
    color: transparent;
}

input[type="time"]::-webkit-datetime-edit-hour-field,
input[type="time"]::-webkit-datetime-edit-minute-field {
    color: black; 
}
