.table{
    outline-style: solid;
    background: white;
    outline-width: 2px;
    max-width: 95% !important;
    margin: 0.5em auto;
}

th, td, tr {
  font-size: 0.9rem;
  text-align: center;
}

#tableToPrint{
  display:none;
}

@media print {
    .ReportPagination{
      display: none !important;
    }
    #FilterClearButton{
      display: none;
    }
    .FormHelperText{
      display: none !important;
    }
    .table{
      display:table;
      font-size: 0.8em;
      margin-top: 5em;
      
  }
  th, td {
      height: 1em;
  }
    .thead-dark{
    color: black !important;
}
  }

.ReportPagination{
    display: flex;
    justify-content: space-around;
}

@media only screen and (max-width: 600px) {
    .table{
        outline-style: solid;
        outline-width: 2px;
        width: 40vh;
        margin: 0.5em auto;
        
    }

    th, td {
        font-size: 0.5rem;
    }
  }

  #FilterClearButton {
    width: 175px;
    height: 57.735px;
    border-style:solid;
    border-color: #A8D9F2;
    background-color: #A8D9F2;
    position: relative;
    color: black;
  }

  .MuiPaginationItem-outlinedPrimary.Mui-selected {
    color: #1976d2 !important;
    border: 1px solid rgba(25, 118, 210, 0.5) !important;
    background-color: rgba(25, 118, 210, 0.12) !important;
}

.printButton{
    text-decoration: none;
    font-size: 15px;
    color: #364954;
    background-color: transparent;
    border-color: transparent;
}
.printButton:hover{
    color: #7EC8EC;
}

.thead-dark{
  background-color: #364954;
  color: white;
}

.FilterBar{
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  background: white;
  padding: 2em;
  width: 90vw;
  margin: 2em auto;
}

.CheckboxesBar{
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  background: white;
  padding: 2em;
  width: 90vw;
  margin: 2em auto;
}

.printButton{
  margin-right: 2em;
float: right}

.FilterSection{
  display: flex;
  justify-content: space-between;
}

p.centered {
  margin: 0;
}

