.TagReadersButtons{
    display: flex;
    justify-content: space-around;
    margin: 1em;
}

#TagReadersForm{
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    padding: 2em;
}

.TagReadersGrid{
    display:flex;
    flex-direction: column;
}