#NoC
{
    /*background:linear-gradient(135deg, #5D6874 0%,#222222 100%);*/
    background: #7EC8ECAA;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.25rem;
    margin: 0.25rem;
    box-shadow:4px 4px 8px 0px rgba( 0, 0, 0, 0.2 );
    flex-direction:column;
    border-radius: 3%;
    
    transform:scale(1);
    transition:transform 0.25s ease-in;
}

#NoC:hover{
    transform:scale(1.1);
    transition:transform 0.25s ease-out;
}

.probeButton{
    background-color: #364954 !important;
    border-style: none !important;
    margin: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}
.probeButton:hover{
    background-color: #99AEBB;
    border-style: none;
}
.probeButton:focus{
    background-color: #364954;
    border-style: none;
}
 
.probeCardSection{
    display:flex;
    flex-direction:column;
}