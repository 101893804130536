/* The sidebar menu */
.sidenav {
  display: flex;
}

@media print {
  .sidenav{
    display: none;
  }
}

/* The navigation menu links */
.sidenav Button {
    padding: 6px 8px 6px 8px;
    text-decoration: none;
    font-size: 25px;
    color: #364954;
    display: block;
    background-color: transparent;
    border-color: transparent;
}

/* When you mouse over the navigation links, change their color */
.sidenav Button:hover {
  color: #7EC8EC;

}

.sidenav Button:focus { background-color: transparent; }
