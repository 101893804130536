.PuC
{
    /*background:linear-gradient(135deg, #5D6874 0%,#222222 100%);*/
    /* background: #7EC8ECAA; */
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0.25rem;
    width: 13rem;
    height: 19rem;
    flex-direction:column;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    padding: .5em;
    border-radius:.5rem;
    transform:scale(1);
    transition:transform 0.25s ease-in;
}

/* @media only screen and (max-width: 950px) {
    .PuC{
      width: 11em !important;
      height: 14em !important;
      font-size: 0.6em;
    }
  
    .PuCInfo p{
      font-size: 0.6em;
    }
    #NozzleBadge, #StatusBadge, .PumpBadges{
        height: 1em !important;
      }
  }*/
  
  @media only screen and (max-width: 675px) {
    .PuC{
      width: 90vw !important;
      margin: 1em !important;
    }
  }

.PuC:hover{
    transform:scale(1.1);
    transition:transform 0.25s ease-out;
}

.pumpButton{
    border-style: none;
    margin: 0.25rem;
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

.PumpStatusButton{
    color: #364954;
    border-style: none;
    margin: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

.PumpTotalizerButton{
    color: #364954;
    border-style: none;
    margin: 0.25rem;
    padding-left: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

.pumpCardSection{
    display:flex;
    flex-direction:column;
}

.PuCInfo{
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: #364954;
    border-radius: 15px;
    margin: 0.2rem;
    color: white;
}

.PuCInfo:hover{
    border-style: none;
}

#NozzleBadge{
    color: #364954;
}

#StatusBadge{
    color: #364954;
}

#NozzleBadge:hover{
    cursor: pointer;
    text-decoration: underline;
}

#TotalizerButton{
    background-color: #7EC8ECAA;
    display: flex;
    justify-content: space-around;
}

#TotalizerButton:hover{
    cursor: pointer;
    background-color: #99AEBB;
}

#StatusButton:hover{
    cursor: pointer;
    background-color: #99AEBB !important;
}

#NozzleButton{
    background-color: #7EC8ECAA !important;
}

#NozzleButton:hover{
    cursor: pointer;
    background-color: #99AEBB !important;
}

#StartButton{
    color: white;
}

#PrePaidButtons{
    margin-top: 0.3em;
    display: flex;
    justify-content: space-around;
}