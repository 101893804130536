.NavBarIcons{
  color: #364954;
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  /*background:linear-gradient(135deg, #5D6874 0%,#222222 100%);*/
  /* background: #7EC8ECAA; */
}

@media only screen and (max-width: 950px) {
  .NavIcons img{
    width: 2em !important;
    height: 2em !important;
  }

  .NavIcons p{
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 675px) {
  .NavIcons img{
    width: 1.5em !important;
    height: 1.5em !important;
  }

  .NavIcons p{
    font-size: 0.5em;
  }
}

@media print {
  .NavBarIcons{
    display: none;
  }
}

#WithBackground{
  background: radial-gradient(rgb(168,217,242) 0%, rgba(168,217,242) 10%, rgba(255,255,255,0.5) 75%);
  /* background-color: #AAAAAA77; */
}


#WithoutBackground{
  background-color: transparent;
}

.NavIcons:hover{
  text-decoration: underline;
  text-decoration-color:#364954 !important;
  cursor: pointer;
}

.NavIcons{
  text-align: center;
  flex-grow: 1;
}

.NavIconsText {
    color: #364954;
    margin: 0;
    font-weight: bold;
    margin-bottom: 1em;
}

@media print {
  #NavBarIcons{
    display: none;
  }
}

/* @media only screen and (max-width: 1000px) {
  .NavIconsText {
    color: #364954;
}

}

@media only screen and (max-width: 1750px) {
  .NavIconsText {
    color: #364954;
}
} */

/* #WithBackground{

  border: 2px solid red;
  border-top-right-radius: 25%;
  border-top-left-radius: 25%;
} */