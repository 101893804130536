#LiquidGauge
{
    display: inline-block;
    text-align: center;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
    padding: .5em;
    background-color: #FFFFFF00;
    border-radius:.5rem;
    margin: .5em;
    transform:scale(1);
    transition:transform 0.25s ease-in;
}

@media only screen and (max-width: 675px) {
    #LiquidGauge{
      width: 100vw !important;
      margin: 1em !important;
    }
  }

#LiquidGauge:hover{
    transform:scale(1.1);
    transition:transform 0.25s ease-out;
}

.TankBadge{
    background-color: #364954aa;
    display: block !important;
    margin: .5em;
}

.TankStatusBadgeActive{
    background-color: #00e676aa;
    display: block !important;
    margin: .5em;
}

.TankStatusBadgeInactive{
    background-color: #f44336aa;
    display: block !important;
    margin: .5em;
}

#ProbeButton:hover{
    cursor: pointer;
}

#FillingButton{
    background-color: rgba(0, 183, 255, 0.667);

}

#FillingButton:hover{
    cursor: pointer;
}
